import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/sharp-solid-svg-icons';
import React from 'react';

import Page from 'site/Page';

import './notFound.css';

const NotFound = ({ location }) => (
  <Page title="Page Not Found">
    <div className="h-100-vh w-100-vw rem-pad-width flex-col-center align-center">
      <FontAwesomeIcon
        icon={faDollarSign}
        size="6x"
        className="blue"
        style={{ paddingBottom: '2px' }}
      />
      <span className="fs-100 fw-700">Oh no!</span>
      <span className="fs-46 fw-700">
        You've found a payment link that no longer exists.
      </span>
      <span className="m-top-24 fs-24 fw-500">
        Please reach out to our store for help regarding your payment.
      </span>
    </div>
  </Page>
);

export default NotFound;
